import { render, staticRenderFns } from "./user-profile-photo-add.html?vue&type=template&id=d48a4292&scoped=true&"
import script from "./user-profile-photo-add.js?vue&type=script&lang=js&"
export * from "./user-profile-photo-add.js?vue&type=script&lang=js&"
import style0 from "./user-profile-photo-add.scss?vue&type=style&index=0&id=d48a4292&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d48a4292",
  null
  
)

export default component.exports